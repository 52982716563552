.cdk-overlay-container {
  position: fixed;
  z-index: 5000;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.mat-mdc-option {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block !important;
  line-height: 48px !important;
  height: 48px !important;
  padding: 0 16px !important;
  text-align: left !important;
  text-decoration: none !important;
  position: relative !important;
  cursor: pointer !important;
  outline: 0 !important;
  display: flex !important;
  flex-direction: row !important;
  max-width: 100% !important;
  box-sizing: border-box !important;
  align-items: center !important;
}

.mat-mdc-option[aria-disabled=true] {
    -webkit-user-select: none;
    user-select: none;
    cursor: default;
    background: rgb(241 228 228 / 70%) !important;
}

.mat-mdc-select-panel {
  background: #fff;
}


.mat-option-text {
  display: inline-block !important;
  flex-grow: 1 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.mat-form-field-label-wrapper {
  position: absolute;
  left: 0;
  box-sizing: content-box;
  width: 150% !important;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}

.mat-mdc-select{
  width: 100%;
}

.mat-mdc-header-row, .mat-mdc-row {
  border-color: #E8EBED;
  
}
.mat-sort-header-content {
  font-weight: 700;
}

.mat-select-trigger {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  background-color: white;
  color: black;
  padding-left: 2px;
  padding-top: 4px;
  width: 100% !important;
  border-radius: 5px !important;
  border: 1px solid #aaa !important;

  .mat-select-arrow-wrapper {
    height: 3px !important;
  }
}

.removeCart {
  mat-icon {
    color: #C00909;
  }
}

.mat-mdc-paginator-page-size {
  display: flex;
  align-items: baseline;
  flex: auto;
}

.mat-mdc-select-panel .mat-mdc-optgroup-label, .mat-select-panel .mat-mdc-option {
  font-size: inherit !important;
  line-height: 3em !important;
  height: 3em !important;
}

.mat-mdc-option.mat-active {
  background: rgba(0,0,0,.04) !important;
  color: rgba(0,0,0,.87) !important;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0,0,0,.32);
}

.mat-mdc-menu-panel.orgLookupMenu {
  max-width: 550px !important;
  width: 550px !important;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: none !important;
  opacity: 0;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.mat-select-placeholder {
  transition: none !important;
  opacity: 0.6;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 300000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.mat-mdc-dialog-container {
  display: block;
  padding: 0px !important;
  border-radius: 16px !important;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}

.mat-mdc-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, .20), 0px 24px 38px 3px rgba(0, 0, 0, .14), 0px 9px 46px 8px rgba(0, 0, 0, .12);
  background: #fff;
  color: rgba(0,0,0,.87);
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.mat-drawer-inner-container {
  background-color: #fff;
  overflow: hidden !important;
  overflow: hidden !important;
  height: auto !important;
  width: auto !important;
}

.mat-drawer {
  overflow-y: hidden !important;
}

.mat-mdc-tab-header {
  border-bottom: 1px solid #f1f3f6;
}

.mat-tab-label-container {
  background-color: #fff;
}

.mat-tab-label-content {
  font-family: Arial, Helvetica, sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
}

.mat-tab-label {
  padding-top: 16px !important;
   color: #000 !important;
  opacity: 1 !important;
}

.mat-tab-body-content {
  overflow: hidden auto !important;
}

.mat-ink-bar {
  background-color: #3F57E8 !important;
  height: 3px !important;
}

.mat-sidenav-container {
  position: fixed;
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 100%;
}

.sidenav__content--height {
  min-height: 80vh;
  height: 100%;
}
.mat-tab-header-pagination{
  background-color: #FFF !important;
  color: #3F57E8;
}
.mat-tab-header-pagination-disabled{
  display: none !important;
}

mat-tab-header {
  background-color: white;
}

.mdc-tab-indicator__content--underline {
  border-top-width: 0px !important;
}

.mdc-tab--active {
  border-bottom: 3px solid #3f57e8 !important;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 300000;
  display: flex;
  max-width: 100%;
  max-height: 100%
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: none !important;
  opacity: 0
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1
}

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: .6
}

.cdk-overlay-dark-backdrop {
  background: rgba(0,0,0,.32)
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.mat-mdc-menu-content:not(:empty) {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.mat-mdc-menu-item {
  max-height: 42px;
  background-color: #fff;
  line-height: 19.5px !important;
  font-size: 14px;
}

.mat-mdc-menu-panel {
  background-color: #fff !important;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.20), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  background-origin: padding-box;
  background-clip: padding-box;
  border-radius: 3px !important;
  border-bottom-color: rgba(0,0,0,0.15);
  border-left-color: rgba(0,0,0,0.15);
  border-right-color: rgba(0,0,0,0.15);
  border-top-color: rgba(0,0,0,0.15);
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  min-height: 42px !important;
  max-width: 400px !important;
}

.mat-mdc-menu-item.orgLookupItem {
  background-color: transparent;
  color: #555;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  padding-top: 12px;
}

.mat-mdc-menu-item.orgLookupItem:hover {
  background-color: #3F57E8;
  color: #fff;
}

.mat-divider {
  color: #e5e5e5;
  margin: 0px 4px !important;
}

.mat-calendar-header {
  padding: 8px 8px 0 8px;
}

.mat-calendar-controls {
  display: flex;
  margin: 5% calc(33% / 7 - 16px);
}

.mat-calendar-period-button {
  min-width: 0;
}

.mat-mdc-button, .mat-mdc-icon-button, .mat-mdc-outlined-button, .mat-mdc-unelevated-button {
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
}

.rn-date-range .mat-mdc-icon-button {
  display:inline-block !important;
}

.mat-calendar-body-label, .mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-mdc-button, .mat-mdc-icon-button, .mat-mdc-outlined-button {
  background: transparent;
  color: inherit;
}

.mat-calendar-arrow {
  border-top-color: rgba(0,0,0,.54);
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top-width: 5px;
  border-top-style: solid;
  margin: 0 0 0 5px;
  vertical-align: middle;
}

.mat-button-ripple.mat-ripple, .mat-button-focus-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
  border-radius: inherit;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}

.mat-button-focus-overlay {
  opacity: 0;
  transition: none !important;
}

.mat-calendar-spacer {
  flex: 1 1 auto;
}

.mat-datepicker-content .mat-calendar-next-button, .mat-datepicker-content .mat-calendar-previous-button, .mat-datepicker-toggle {
  color: rgba(0,0,0,.54)
}

.mat-calendar-table-header {
  color: rgba(0,0,0,.38)
}

.mat-calendar-table-header-divider:after {
  background: rgba(0,0,0,.12)
}

.mat-calendar-body-label {
  color: rgba(0,0,0,.54)
}

.mat-calendar-body-cell-content, .mat-date-range-input-separator {
  border-color: transparent;
  color: rgba(0,0,0,.87)
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0,0,0,.38)
}

.mat-calendar-body-in-preview {
  color: rgba(0,0,0,.24)
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0,0,0,.38)
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0,0,0,.18)
}

.mat-calendar-body-in-range:before {
  background: rgba(63,81,181,.2)
}

.mat-calendar-body-comparison-identical, .mat-calendar-body-in-comparison-range:before {
  background: rgba(249,171,0,.2)
}

.mat-calendar-body-comparison-bridge-start:before, [dir=rtl] .mat-calendar-body-comparison-bridge-end:before {
  background: linear-gradient(90deg,rgba(63,81,181,.2) 50%,rgba(249,171,0,.2) 0)
}

.mat-calendar-body-comparison-bridge-end:before, [dir=rtl] .mat-calendar-body-comparison-bridge-start:before {
  background: linear-gradient(270deg,rgba(63,81,181,.2) 50%,rgba(249,171,0,.2) 0)
}

.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range:after, .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical {
  background: #a8dab5
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected, .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e
}

.mat-calendar-body-selected {
  background-color: #3f51b5;
  color: #fff
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(63,81,181,.4)
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #fff
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(63,81,181,.3)
}

.mat-datepicker-content {
  background-color: #fff;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12);
  color: rgba(0,0,0,.87)
}

.mat-datepicker-content.mat-accent .mat-calendar-body-in-range:before {
  background: rgba(255,64,129,.2)
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical, .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range:before {
  background: rgba(249,171,0,.2)
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start:before, .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end:before {
  background: linear-gradient(90deg,rgba(255,64,129,.2) 50%,rgba(249,171,0,.2) 0)
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end:before, .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start:before {
  background: linear-gradient(270deg,rgba(255,64,129,.2) 50%,rgba(249,171,0,.2) 0)
}

.mat-date-range-input {
  display: inline;
}

.mat-date-range-input-start-wrapper {
  display: inline;
}

.mat-date-range-input-separator {
   display: inline;
}

.mat-date-range-input-end-wrapper {
   display: inline;
}

.mat-datepicker-toggle {
  display: inline;
}

.mat-form-field-wrapper {
  height: 100%;
}

.mat-form-field-flex {
  height: 100%;
}

.mat-form-field-outline {
  height: 100%;
}

.mat-form-field-wrapper {
  margin-top: 0px !important;
}

.mat-mdc-text-field-wrapper {
  min-height: 100% !important;
}

.mat-datepicker-toggle-default-icon {
  position: absolute;
  top: 4px;
}

.ng-invalid .mat-datepicker-toggle-default-icon {
  color: #c30017;
}

.mat-form-field-type-mat-date-range-input.ng-invalid {
  color: #c30017;
}

.mat-date-range-input-container {
  display: flex;
  align-items: inherit !important;
  padding-top: 8px;
}

.mat-mdc-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ffd740
}

.mat-mdc-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #673ab7
}

.mat-mdc-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336
}

.mat-mdc-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: #000
}

label[for=sameBillingAddress-input] {
  padding-left: 0px;
  margin-bottom: 0px;
  font-size: 14px;
  color: #000000de;
}

mat-radio-button .mdc-form-field label {
  padding-left: 0px;
  margin-bottom: 0px;
}

mat-slide-toggle .mdc-form-field label {
  margin-bottom: 0px;
}

mat-radio-button .mdc-radio {
  padding: 5px !important;
}

mat-table mat-header-row {
  border-bottom: 1px solid #d9d9d9 !important;
}

mat-table mat-row {
  border-bottom: 1px solid #d9d9d9 !important;
}

mat-header-cell {
  border-bottom: none !important;
}

mat-cell {
  border-bottom: none !important;
}

.mdc-radio__outer-circle {
  border-color: black !important;
}

.mdc-radio__inner-circle {
  border-color: #3f57e8 !important;

  :hover {
    border-color: #3f57e8 !important;
  }
}

.mdc-switch__track::after {
  background: #9faaed !important;

  :hover {
    background: #9faaed !important;
  }
}

.mdc-switch--selected .mdc-switch__handle::after {
  background: #3f57e8 !important;

  :hover {
    background: #3f57e8 !important;
  }
}

.mdc-checkbox__background:hover {
  border-color: #3f57e8 !important;
}

.mat-mdc-checkbox-checked .mdc-checkbox__background {
  border-color: #3f57e8 !important;
  background-color: #3f57e8 !important;
}

.mat-mdc-form-field-type-mat-date-range-input {
  background-color: #f9f9f9 !important;
}

.mat-calendar-previous-button {
  min-width: auto !important;
}

.mat-calendar-next-button {
  min-width: auto !important;
}

.mat-mdc-menu-content {
  background-color: white;
}

.mat-drawer-side {
  border-right: none !important;
}
