@import './_variables.scss';

.has-error {
  label {
    color: #c30017;

    &.no-error-color-label {
      color: black;
    }
  }

  .help-block {
    margin: 0;
    background-color: $danger-red;
    color: $white;
    padding: 1px 3px;
  }

  a {
    color: #c30017;
    text-decoration: underline;
  }

  input, textarea {
    border-color: #c30017 !important;
  }

  select {
    border-color: #c30017;
    color: #c30017;
  }

  .ui-dropdown {
    border-color: #c30017 !important;
    color: #c30017 !important;
  }

  .ui-dropdown-trigger-icon {
    color: #c30017 !important;
  }

  .ui-inputnumber {
    color: #c30017 !important;
  }

  p-inputNumber {
    &.ng-invalid {
      color: #c30017 !important;
    }
  }
}
.inputMask > input{
    font-family: Arial,Helvetica,sans-serif;
    font-size: 14px!important;
    background-image: none;
    border: 1px solid #d4d8da;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px #00000013;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.inputMask > input:focus{
  border: 1px solid #3F57E8;
}
// form standards
fieldset {
  &:disabled, [disabled] {
    opacity: 0.38;
  }
}

input[type='radio'] {
  transform: scale(1.5);
}

.ui-inputtext, .p-inputtext {
  height: 42px;
  width: 100%;
}

.well {
  background-color: #FFF;
  color: #141C44;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 19px;
  margin-bottom: 20px;
  margin-left: 0px !important;
  border-radius: 16px;
  border: 1px solid rgba(100, 100, 100, 0.15);
}

.orgExplorer {
  max-height: 500px;
  overflow: hidden;
  .ui-tree {
    width: 100%;
  }
}

.userExplorer {
  background-color: #fff;
  padding: 0;
  height: 100%;
}

.client-access-treeview {
  height: auto;
  overflow-y: auto;
  padding-bottom: 15px;
}

.p-field-checkbox, .p-field-radiobutton {
  margin-bottom: 0.25rem !important;
}

.ui-dropdown, .p-select {
  min-width: 100% !important;
  border-radius: 8px;
  border: 1px solid rgba(100, 100, 100, 0.15);
}
.p-select {
  max-width: 100%;
  height: 42px;
}
.p-select-label {
  margin-left: 8px !important;
  margin-top: 7px;
}
.p-select-trigger {
  padding: 0 0.5em;
}

p-select {
  &.width-100-percent {
    .ui-dropdown {
      width: 100% !important;
    }
  }

  &.width-40-percent {
    .ui-dropdown {
      width: 40% !important;
    }
  }
}

.ui-widget-header .ui-inputtext, .ui-widget-content .ui-inputtext {
  &.width-40-percent {
    width: 40% !important;
  }

  &.width-100-percent {
    width: 100% !important;
  }
}

.form-control {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px !important;
  background-image: none;
  border: 1px solid #d4d8da;
  border-radius: 8px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;

  &.width-40-percent {
    width: 40% !important;
  }

  &.width-100-percent {
    width: 100% !important;
  }

  &.inline {
    display: inline !important;
  }
}

.org-user-search-edit {
  width: 100%;
}

.primary-frame-search-edit {
  width: 500px;
}

@media only screen and (max-width: 798px) {
  /* For mobile phones: */
  .well {
    margin-top: 60px;
  }
}
