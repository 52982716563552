:root {
  --layer-0: #ffffff;
  --layer-1: #f4f4f4;
  --layer-2: #eaeaea;
  --text-color: #333333;
  --text-color-secondary: #848484;
  --primary-color: #770000;
  --primary-color-text: #ffffff;
}

body .ui-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container:not(.ui-state-disabled).ui-state-focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: #007ad9;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-datepicker:not(.ui-state-disabled) .ui-datepicker-header .ui-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-title select:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-datepicker table td > a:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-chkbox .ui-chkbox-box.ui-state-focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: #007ad9;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-chips > ul.ui-inputtext:not(.ui-state-disabled).ui-state-focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: #007ad9;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-dropdown:not(.ui-state-disabled).ui-state-focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: #007ad9;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-inputswitch.ui-inputswitch-focus .ui-inputswitch-slider {
  background: #b7b7b7;
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-inputtext:enabled:focus:not(.ui-state-error) {
  outline: 0 none;
  outline-offset: 0;
  border-color: #007ad9;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-listbox .ui-listbox-list .ui-listbox-item:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-multiselect:not(.ui-state-disabled).ui-state-focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: #007ad9;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-radiobutton .ui-radiobutton-box.ui-state-focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: #007ad9;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-rating:not(.ui-state-disabled):not(.ui-rating-readonly) a:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active).ui-state-focus {
  z-index: 1;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-slider .ui-slider-handle:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active).ui-state-focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-button:enabled:focus {
  outline: 0 none;
  outline-offset: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-button.ui-state-default.ui-button-secondary:enabled:focus, body .ui-buttonset.ui-button-secondary > .ui-button.ui-state-default:enabled:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-button.ui-state-default.ui-button-info:enabled:focus, body .ui-buttonset.ui-button-info > .ui-button.ui-state-default:enabled:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-button.ui-state-default.ui-button-success:enabled:focus, body .ui-buttonset.ui-button-success > .ui-button.ui-state-default:enabled:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-button.ui-state-default.ui-button-warning:enabled:focus, body .ui-buttonset.ui-button-warning > .ui-button.ui-state-default:enabled:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-button.ui-state-default.ui-button-danger:enabled:focus, body .ui-buttonset.ui-button-danger > .ui-button.ui-state-default:enabled:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-dataview .ui-dataview-layout-options .ui-button:focus {
  outline: 0 none;
  outline-offset: 0px;
  z-index: 1;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .fc .fc-event:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.ui-progress-spinner-svg {
  animation: ui-progress-spinner-rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 40%;
  position: absolute;
  top: -54%;
  left: -370%;
}

.ui-progress-spinner {
  position: relative;
  /* margin: auto; */
  padding-left: 0;
  margin-left: 150px;
  width: 100px;
  height: 100px;
  display: flex;
  /* color: blue; */
}

.ui-tooltip-text.ui-shadow.ui-corner-all { 
  background-color: white;
  color: #000000;
  width: fit-content;
}

.p-tooltip-text {
  background-color: white !important;
  color: #000000 !important;
  width: fit-content;
  box-shadow: 0 1px 3px #0000004d !important;
  padding: .125em .5em !important;
}

.p-tooltip .p-tooltip-arrow {
  display: none;
}

body .fc .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .fc .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-orderlist .ui-orderlist-list .ui-orderlist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-organizationchart .ui-organizationchart-node-content .ui-node-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-paginator .ui-paginator-first:focus,
body .ui-paginator .ui-paginator-prev:focus,
body .ui-paginator .ui-paginator-next:focus,
body .ui-paginator .ui-paginator-last:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-picklist .ui-picklist-list .ui-picklist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-table .ui-sortable-column:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-treetable .ui-sortable-column:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

// body .ui-accordion .ui-accordion-header a:focus {
//   outline: 0 none !important;
//   outline-offset: 0 !important;
//   -webkit-box-shadow: none !important;
//   -moz-box-shadow: none !important;
//   box-shadow: #a6d5fa !important;
//   border-color: #007ad9;
// }


body .ui-fieldset .ui-fieldset-legend a:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-tabview.ui-tabview-top .ui-tabview-nav li .ui-tabview-close:focus, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li .ui-tabview-close:focus, body .ui-tabview.ui-tabview-left .ui-tabview-nav li .ui-tabview-close:focus, body .ui-tabview.ui-tabview-right .ui-tabview-nav li .ui-tabview-close:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-tabview.ui-tabview-top .ui-tabview-nav li:focus, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:focus, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:focus, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-lightbox .ui-lightbox-caption .ui-lightbox-close:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-overlaypanel .ui-overlaypanel-close:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-fileupload-choose.ui-state-focus {
  outline: 0 none;
  outline-offset: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-breadcrumb ul li .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-contextmenu .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-megamenu .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-menu .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-menubar .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-panelmenu .ui-panelmenu-header > a:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-slidemenu .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-steps .ui-steps-item .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-tieredmenu .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-messages .ui-messages-close:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .ui-inplace .ui-inplace-display:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}


.ui-table-loading {
  opacity: 0.5;
}

body .ui-tabview.ui-tabview-top.userDemo .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover {
  border: none;
  background-color: #dbdbdb;
}

body .ui-tabview.ui-tabview-top.userDemo .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a {
  color: #657881;
}

body .ui-tabview.ui-tabview-top.userDemo .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover .ui-tabview-close, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover .ui-tabview-close, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover .ui-tabview-close, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover .ui-tabview-close {
  color: #657881;
}

body .ui-tabview.ui-tabview-top.userDemo .ui-tabview-nav li, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li, body .ui-tabview.ui-tabview-left .ui-tabview-nav li, body .ui-tabview.ui-tabview-right .ui-tabview-nav li {
  border: none;
}

body .ui-tabview.ui-tabview-top.userDemo .ui-tabview-nav li a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li a {
  float: none;
  display: inline-block;
  color: #657881;
  padding: 0.571em 1em;
  font-weight: 700;
  font-size: 16px;
  background-color: #ffffff;
  border-bottom: 1px #333333;
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
  background-color: #333333;
  color: #ffffff;
}

body .ui-tabview.ui-tabview-top.userDemo .ui-tabview-nav li.ui-state-active:hover, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover {
  border-left: none;
  border-right: none;
  border-top: none;
}

body .ui-tabview .ui-tabview-panels {
  padding: 0px;
  border-left: 0;
  border-right: 0;
}

body .ui-dropdown .ui-dropdown-label {
  padding-top: 8px;
  font-size: 14px;
}

.ui-placeholder {
  color: #848484 !important
}

.ui-dropdown .ui-dropdown-trigger .ui-dropdown-trigger-icon {
  margin-left: -.6em;
}

.ui-dropdown .ui-dropdown-label {
  line-height: 2;
}

.ui-dropdown.ui-state-disabled .ui-dropdown-label {
  cursor: not-allowed !important;
  background: #e9ecef !important;
}

.ui-dropdown.ui-state-disabled .ui-dropdown-trigger, .ui-dropdown.ui-state-disabled .ui-dropdown-label {
  cursor: not-allowed !important;
  background: #e9ecef !important;
}

.ui-state-disabled, .ui-widget:disabled {
  opacity: 1 !important;
  cursor: not-allowed !important;
  background: #e9ecef !important;
}

ui-dropdown .ui-dropdown-trigger {
  margin-right: 6px;
}

.ui-dropdown {
  height: 42px;
  border: 1px solid #d4d8da;
}

.ui-inputnumber {
  width: 100%;
}

body .ui-table .ui-table-tbody > tr:nth-child(even) {
  background-color: #ffffff;
}


.ui-table .ui-helper-hidden-accessible:after {
  content: "Number of records";
}

.ui-table .ui-paginator {
  background-color: #ffffff;
  border: none;
}

.ui-table .pi-sort-amount-up-alt:before {
  content: "\e914";
}

.ui-table .pi-sort-amount-down:before {
  content: "\e913";
}

.ui-table .pi-sort-amount-up:before {
  content: "\e914";
}

.ui-table .pi-sort-alt:before {
  content: "\e915";
}

body .ui-table .ui-table-tbody > tr > td {
  border: none !important;
  padding: 0.25em 0.5em;
}
p-table > .ui-table {
  margin-left: -5px;
}
//overlay panel
.p-overlaypanel .p-overlaypanel-content {
  padding: 1rem;
}

.p-component, .p-component * {
  box-sizing: border-box;
}

.ui-overlaypanel-content, .p-overlaypanel-content {
  padding: 0.5em 1em;
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  z-index: 99 !important;
  transform-origin: center bottom;
  --overlayArrowLeft: 0px;
  opacity: 1;
  height: fit-content;
  padding: 10px;
  transform: none !important;
  opacity: 1;
  position: fixed;
  width: 35%;
  margin-top: 4%;
  margin-left: 2.7%;
}
.p-overlaypanel-content {
  margin-top: 8.2%;
  margin-left: 36.7%;

  .p-progress-spinner {
    width: 40px;
    height: 40px;
  }
}

.ui-overlaypanel, .p-overlaypanel {
  padding: 0;
  margin: 0;
  width: fit-content;
  transform: none !important;
  top: 0 !important;
  left: 0 !important;
  margin-top: 0.5%;
  margin-left: 0%;
  z-index: 1101 !important;
}

.p-component {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: normal;
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
  color: #ffffff;
  background-color: #2c6da3;
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page:not(.ui-state-active):hover {
  background-color: #eeeeee;
  color: #23527c;
}

body .ui-table .ui-sortable-column:focus {
  box-shadow: none;
}

body .ui-table .ui-sortable-column .ui-sortable-column-icon {
  color: #C2C8CB;
}

body .ui-table .ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
  color: #333333;
}

body .ui-paginator > p-select:before {
  content: "Show"
}

body .ui-paginator > p-select:after {
  content: " results per page"
}


.ui-table .ui-helper-hidden-accessible:after {
  content: "Number of records";
}

.ui-table .ui-paginator {
  background-color: #ffffff;
  border: none;
}

body .ui-table .ui-table-thead > tr > th {
  border: none;
  color: black;
  background-color: #F0F0F0;
  text-transform: capitalize;
  font-size: 12px;
  padding: .25em .5em;
}
.packageAssignUser > .ui-table > .ui-table-wrapper > table > .ui-table-thead > tr > th,
.packageAssignUser > .p-datatable > .ui-table-wrapper > table > .p-datatable-thead > tr > th{
  font-size: 12px !important;
  font-weight: 700 !important;
  height: 32px !important;
}
.packageAssignUser > .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr > td,
.packageAssignUser > .p-datatable > .ui-table-wrapper > table > .p-datatable-tbody > tr > td {
  height: 56px !important;
}
.packageAssignUser > .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr>.userLink >span > .no-text-decoration,
.packageAssignUser > .p-datatable > .ui-table-wrapper > table > .p-datatable-tbody > tr >.userLink >span > .no-text-decoration{
  color: #3F57E8 !important;
}
.tableHeadingColorClass > .ui-table >.ui-table-wrapper,
.tableHeadingColorClass > .p-datatable > .ui-table-wrapper {
  border: 2px solid rgba(100, 100, 100, 0.15);
  border-radius: 10px;
}

// new primeng 14 style
.tableHeadingColorClass > .p-datatable.p-component
{
  // margin-left: -5px;
  margin-top: 10px;
  
  & >.p-datatable-wrapper{
    border: 2px solid rgba(100, 100, 100, 0.15);
    border-radius: 10px;
    table[role=table] {
      table-layout: fixed;
      font-size: 0.9rem !important;
      thead {
        tr {
          height: 32px;
          th {
            border: none;
            color: #000;
            background-color: #f0f0f0;
            text-transform: capitalize;
            font-size: 12px;
            padding: 0.25em 0.5em;
          }
        }
      }
      tbody {
        td {
          border: none !important;
          border-bottom: 1px solid rgba(100,100,100,.15) !important;
          padding: 0.25em 0.5em;
        }
      }
    }
  }
} 
.tableHeadingColorClass .ui-table > .ui-table-wrapper > table > .ui-table-thead > tr > th{
  background-color: #F0F0F0;
}
.tableHeadingColorClass .ui-table > .ui-table-wrapper > table > .ui-table-thead > tr{
  height: 32px;
}
.tableHeadingColorClass .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr >td{
  border-bottom: 2px solid #F0F0F0;
}
.tableRowHeight .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr{
  height: 40px !important;
}
.tableHeadingColorClass .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr:last-child>td:last-child{
  border-bottom-right-radius: 10px;
}
.tableHeadingColorClass .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr:last-child>td:first-child{
  border-bottom-left-radius: 10px;
}
.tableHeadingColorClass .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr>td{
  border-bottom: 1px solid rgba(100, 100, 100, 0.15) !important;
}
.tableHeadingColorClass .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr:last-child >td{
  border:none !important;
}
.tableHeadingColorClass .ui-table > .ui-table-wrapper > table > .ui-table-thead > tr > th:first-child{
  border-top-left-radius: 10px;
}
.tableHeadingColorClass .ui-table > .ui-table-wrapper > table > .ui-table-thead > tr > th:last-child{
  border-top-right-radius: 10px;
}
.tableHeadingColorClass .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr > .userslist-multi-value-top > span >span > a > .userslist-multi-value-top{
  color: #555;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}
.tableHeadingColorClass .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr > .userslist-multi-value-top > span >span > a >.userslist-multi-value-top > .userslist-multi-value-bottom{
  color: #3F57E8;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.tableHeadingColorClass .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr > td > span >span {

  color: #323b3e;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.tableHeadingColorClass .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr > td > span > .linkStyle {
  color: #3F57E8 !important;
  text-decoration: underline !important;
}
.tableHeadingColorClass .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr > td > span > .no-text-decoration{
  text-decoration: none ;
}
.tableHeadingColorClass .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr > td > span > a :hover{
  text-decoration: underline !important;
}
.tableHeadingColorClass .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr > .search-user-results-role > span >div {

  color: #323B3E;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.packageAddonTable > .ui-table > .ui-table-wrapper > table > .ui-table-thead > tr,
.packageAddonTable > .p-datatable > .p-datatable-wrapper > table > .p-datatable-thead > tr {
  height: 43px !important;
  font-size: 0.9rem !important;
}
.packageAddonTable > .ui-table >.ui-table-wrapper,
.packageAddonTable > .p-datatable >.p-datatable-wrapper{
  border: 1px solid rgba(100, 100, 100, 0.15);
  border-radius: 10px;
}
.packageAddonTable > .ui-table > .ui-table-wrapper > table > .ui-table-thead > tr > th,
.packageAddonTable > .p-datatable > .p-datatable-wrapper > table > .p-datatable-thead > tr > th{
  font-size: 16px;
  font-weight: 700;
  background-color: #FFF !important;
  font-family: Arial, Helvetica, sans-serif;
  border-bottom: 1px solid rgba(100, 100, 100, 0.15);
}
.packageAddonTable > .ui-table > .ui-table-wrapper > table > .ui-table-thead > tr > th:first-child,
.packageAddonTable > .p-datatable > .p-datatable-wrapper > table > .p-datatable-thead > tr > th:first-child{
  border-top-left-radius: 10px !important;
  padding-left: 22px !important;
}
.packageAddonTable > .ui-table > .ui-table-wrapper > table > .ui-table-thead > tr > th:last-child,
.packageAddonTable > .p-datatable > .p-datatable-wrapper > table > .p-datatable-thead > tr > th:last-child{
  border-top-right-radius: 10px !important;
}
.packageAddonTable .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr > td,
.packageAddonTable .p-datatable > .p-datatable-wrapper > table > .p-datatable-tbody > tr > th{
  border-bottom: 1px solid rgba(100, 100, 100, 0.15) !important;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.packageAddonTable .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr,
.packageAddonTable .p-datatable > .p-datatable-wrapper > table > .p-datatable-tbody > tr{
  height: 38px;
  border-bottom: 1px solid rgba(100,100,100,.15)!important;
  font-size: 0.9rem !important;
}
.packageAddonTable .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr > td:first-child,
.packageAddonTable .p-datatable > .p-datatable-wrapper > table > .p-datatable-tbody > tr > td:first-child {
  padding-left: 22px;
}
.packageAddonTable .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr:last-child > td:first-child,
.packageAddonTable .p-datatable > .p-datatable-wrapper > table > .p-datatable-tbody > tr:last-child > td:first-child{
  border-bottom-left-radius: 10px !important;
}
.packageAddonTable .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr:last-child > td
.packageAddonTable .p-datatable > .p-datatable-wrapper > table > .p-datatable-tbody > tr:last-child > td{
  border-bottom: none !important
}
.packageAddonTable .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr:last-child > td:last-child
.packageAddonTable .p-datatable > .p-datatable-wrapper > table > .p-datatable-tbody > tr:last-child > td:last-child{
  border-radius: 10px !important;
}
.invoiceTable >.ui-table > .ui-table-wrapper > table > .ui-table-thead > tr > th,
.invoiceTable > .p-datatable > .p-datatable-wrapper > table > .p-datatable-thead > tr > th {
  background-color: #FFF !important;
  color: rgba(0, 0, 0, 0.80);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding-left: 0px !important;
  border-bottom: 1px solid #AAA !important;
}
.invoiceTable >.ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr > td{
  border-bottom: 1px solid #AAA !important;
  color: rgba(0, 0, 0, 0.80);
  padding-left: 0px !important;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.invoiceTable >.ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr > td > span > span{
  word-break: break-word;
}
.invoiceTable >.ui-table{
  margin-left: 0px !important;
}
.gridMatIcon > .mat-button-wrapper{
  border-radius: 10px;
  padding: 8px 4px;
  border: 1px solid rgba(100, 100, 100, 0.15);
  background: #FFF;
}
.shoppingCart .ui-table > .ui-table-wrapper > table > .ui-table-thead > tr > th{
  background-color: #FFF;
  color: #555;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400 !important;
}
.shoppingCart .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr > td{
  background-color: #FFF;
  color: #555;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  border-top: 2px solid #AAA !important;
}
body .ui-table .ui-sortable-column.ui-state-highlight {
  color: #333333;
  background-color: #ffffff;
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
  color: #ffffff;
  background-color: #2c6da3;
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page:not(.ui-state-active):hover {
  background-color: #eeeeee;
  color: #23527c;
}

body .ui-table .ui-sortable-column:focus {
  box-shadow: none;
}

body .ui-paginator > p-select:before {
  content: "Show"
}

body .ui-paginator > p-select:after {
  content: " results per page"
}

body .ui-paginator .ui-dropdown {
  min-width: 45px !important;

  .ui-inputtext {
    max-height: 30px !important;
  }
}

body .ui-paginator .ui-dropdown-label {
  padding-top: 2px;
  padding-bottom: 2px;
}

body .ui-table .ui-sortable-column:not(.ui-state-highlight):hover {
  background-color: #ffffff;
  color: #333333;
}

body .ui-table.ui-table-hoverable-rows .ui-table-tbody > tr.ui-selectable-row:not(.ui-state-highlight):hover {
  cursor: pointer;
  background-color: #ffffff;
  color: #333333;
}

body .ui-sidebar {
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: none;
}
body .p-drawer {
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: none;
  border-radius: 16px 0 0 16px;

  .p-drawer-content {
    min-height: 700px;
  }
}

.sidebarBackgroundZIndex > .p-drawer {
  z-index: 1101;
}

body > .p-drawer-mask {
  z-index: 1100;
}

.ui-sidebar-close,
.p-drawer-close {
  position: absolute;
  top: 30px;
  z-index: 1;
  right: 35px;
  font-size: 10px;
  border-radius: 10px;
  padding: 10px 10px 6px 10px;
  border: 1px solid #64646426;
  box-shadow: 0px 1px 2px 0px #0000001A;
}
.purchaseLicense > .ui-sidebar-right{
  padding: 0px !important;
}
.purchaseLicense > .ui-sidebar-right > .ui-sidebar-close{
  position: fixed;
  z-index: 1;
  margin-top: 18px !important;
  margin-left: 93%;
  border: 2px solid rgba(100, 100, 100, 0.15);
  height: 40px;
  width: 40px;
  padding: 11px 10px 11px 11px;
  border-radius: 10px;
}
.clientAccess > div{
  width: 40% !important;
}
.clientAccess > .ui-sidebar-right > .ui-sidebar-close{
  position: absolute;
  top: 20px;
  z-index: 1;
  right: 3%;
  font-size: 10px;
  border-radius: 10px;
  padding: 10px 10px 6px 10px;
  border: 1px solid #64646426;
  box-shadow: 0px 1px 2px 0px #0000001A;
}
.viewEvents >.ui-sidebar-right > .ui-sidebar-close{
  position: absolute;
  top: 15px;
  z-index: 1;
  right: 3%;
  font-size: 10px;
  border-radius: 10px;
  padding: 10px 10px 6px 10px;
  border: 1px solid #64646426;
  box-shadow: 0px 1px 2px 0px #0000001A;
}
.purchaseSubscription > div {
  width: 40% !important;
}

.purchaseSubscription > .ui-sidebar-right{
  padding: 0px !important;
}
.purchaseSubscription > .ui-sidebar-right > .ui-sidebar-close{
  position: fixed;
  height: 40px;
  width: 40px;
  z-index: 1;
  top: 20px;
  right: 25px;
  font-size: 10px;
  border-radius: 10px;
  padding: 13px 14px 6px;
  border: 1px solid rgba(100,100,100,.1490196078);
}

.assignUsers > .ui-sidebar-right{
  padding: 0px !important;
}
.assignUsers > .ui-sidebar-right > .ui-sidebar-close{
  position: fixed;
  z-index: 1;
  margin-top: -6px !important;
  margin-left: 93%;
  border: 2px solid rgba(100, 100, 100, 0.15);
  height: 40px;
  width: 40px;
  padding: 11px 10px 11px 12px;
  border-radius: 10px;
}
body .ui-inputtext {
  border-color: #d4d8da;
}

.ui-treenode-label.ui-state-highlight {
  background-color: #ffffff !important;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #000000 !important;
}

.ui-tree-toggler {
  margin-left: -1.1em !important;
}

.ui-tree-container {
  padding-left: 1.2em !important;
}

body .ui-tabview.ui-tabview-top.userDemo .ui-tabview-nav li.ui-tabview-selected a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-tabview-selected a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-tabview-selected a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-tabview-selected a {
  cursor: pointer;
  border-bottom: 4px solid #3F57E8 !important;
}

body .ui-tabview.ui-tabview-top.userDemo .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active {
  background-color: #007ad9;
  border: none;
}

body .ui-tabview.ui-tabview-top.fullTabPanel {
  width: 100%;
}

body .ui-tabview.userDemo .ui-tabview-panels {
  padding: 0px;
}

body .ui-tabview.ui-tabview-top.userDemo .ui-tabview-nav li.ui-state-active {
  background-color: #ffffff;
}

body .ui-tabview.ui-tabview-top.userDemo .ui-tabview-nav li.ui-state-active:hover {
  background-color: #ffffff;
}

body .ui-tabview.ui-tabview-top.userDemo .ui-tabview-nav li.ui-state-active a, .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active a {
  color: #272727;
  font-size: 14px;
  padding-left: 2PX;
  padding-right: 2px;
}

body .ui-tabview.ui-tabview-top.userDemo .ui-tabview-nav li.ui-state-active:hover a, .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover a {
  color: #272727;
  font-size: 14px;
}

body .ui-tabview.ui-tabview-top.userDemo .ui-tabview-nav.userDemo li.ui-state-active, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active {
  background-color: none;
}

body .ui-widget-overlay {
  background-color: rgb(0 0 0 / 40%);
}
body .p-component-overlay {
  background-color: rgb(0 0 0 / 40%);
}

body .p-treenode-children {
  margin-left: 20px;
}

body .ui-chkbox .ui-chkbox-box.ui-state-active,
body .p-checkbox .p-checkbox-box.p-highlight {
  margin-left: -1px;
  border-color: rgb(0 122 217);
  background-color: rgb(0 122 217);
  color: rgb(255 255 255);
  width: 18px;
  height: 18px;
  border-radius: 3px;
  transition: none !important;
  margin-bottom: 2px;
  margin-top: -1px;
}

.client-access-treeview .p-checkbox .p-checkbox-box.p-highlight {
  background-color: #fff;
  border: 2px solid rgba(100,100,100,.3);
  color: #000;
}

.checkboxStyle {
  background-color: rgb(255 255 255);
  display: block;
  width: 18px;
  height: 18px;
  text-align: center;
  border-radius: 3px;
  transition: none !important;
}

.ui-accordion .ui-accordion-header a {
  display: block;
  padding: 0.5em;
  text-decoration: none;
}

body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li, body .ui-tabview.ui-tabview-left .ui-tabview-nav li, body .ui-tabview.ui-tabview-right .ui-tabview-nav li, body .ui-tabview.ui-tabview-top .ui-tabview-nav li {
  border: 1px solid rgb(200 200 200);
  background-color: rgb(244 244 244);
  transition: none !important;
}

body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active .ui-tabview-close, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active .ui-tabview-close, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active .ui-tabview-close, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active a, body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active .ui-tabview-close, body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active a {
  color: rgb(255 255 255);
  background-color: rgb(0 122 217)
}

body .ui-tabview.ui-tabview-top .ui-tabview-nav {
  margin-bottom: -1px;
  border-bottom: 1px solid rgb(200 200 200);
  padding-left: 0px;
}

body .ui-accordion .ui-accordion-header a,
body .p-accordion .p-accordion-header a {
  padding: 1rem;
  color: #495057;
  background: #f9fafa;
  font-weight: 600;
  border-radius: 3px;
  transition: none !important;
}

body .p-accordion .p-accordion-header a {
  text-decoration: none !important;
  p-header {
  height: 28px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  }
}


.p-accordion .p-accordion-content {
  color: rgb(51 51 51);
}

body .ui-accordion .ui-accordion-content {
  border: 1px solid rgb(200 200 200);
  background-color: rgb(255 255 255);
  color: rgb(51 51 51);
  padding: 0.571em 1em;
}

.ui-tabview .ui-tabview-panel {
  border-width: 0;
  padding: 0;
  background: 0;
}

body .ui-radiobutton .ui-radiobutton-box.ui-state-active {
  border-color: rgb(0 122 217);
  background-color: rgb(0 122 217);
  color: rgb(255 255 255);
}

body .ui-radiobutton .ui-radiobutton-box {
  border: 1px solid rgb(166 166 166);
  background-color: rgb(255 255 255);
  width: 18px;
  height: 18px;
  text-align: center;
  position: relative;
  transition: none !important;
  border-radius: 50%;
}

body .ui-radiobutton .ui-radiobutton-box.ui-state-active .ui-radiobutton-icon {
  background-color: rgb(255 255 255);
}

body .ui-radiobutton .ui-radiobutton-box .ui-radiobutton-icon {
  background: rgb(0 0 0 / 0%);
  width: 10px;
  height: 10px;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -5px;
  margin-top: -5px;
  transition: none !important;
  border-radius: 50%;
  font-size: 11px;
}

body .ui-chkbox .ui-chkbox-box {
  border: 2px solid rgb(100, 100, 100, 0.30);
  background-color: rgb(255 255 255);
  width: 19px;
  height: 18px;
  text-align: center;
  border-radius: 3px;
  transition: none !important;
  margin-top: -1px;
  margin-left: -1px;
}

.ui-tree .ui-chkbox .ui-chkbox-icon {
  margin-left: 0px;
  margin-top: 2px;
  font-size: 15px;
}

body .p-checkbox .p-checkbox-box {
  border: 2px solid rgb(100, 100, 100, 0.30);
  width: 19px;
  height: 18px;
  text-align: center;
  border-radius: 3px;
  margin-top: -1px;
  margin-left: -1px;
}

body .p-checkbox .p-checkbox-box {
  margin-left: 0px;
  margin-top: 2px;
  font-size: 15px;
}
.ui-dialog-content, .p-dialog-content{
  border-radius: 16px !important;
}
a{
  color: #3F57E8;
}

.wlp-inventory-table > .ui-table > .ui-table-wrapper > table > .ui-table-thead > tr {
  height: 43px !important;
}

.wlp-inventory-table > .ui-table > .ui-table-wrapper {
  border: 1px solid rgba(100, 100, 100, 0.15);
  border-radius: 10px;
}

.wlp-inventory-table > .p-datatable > .p-datatable-wrapper {
  border: 1px solid rgba(100, 100, 100, 0.15);
  border-radius: 10px;
}

.wlp-inventory-table > .ui-table > .ui-table-wrapper > table > .ui-table-thead > tr > th {
  font-size: 16px;
  font-weight: 700;
  background-color: #FFF !important;
  font-family: Arial, Helvetica, sans-serif;
  border-bottom: 1px solid rgba(100, 100, 100, 0.15);
}

.wlp-inventory-table > .ui-table > .ui-table-wrapper > table > .ui-table-thead > tr > th:first-child {
  border-top-left-radius: 10px !important;
  padding-left: 22px !important;
}

.wlp-inventory-table > .ui-table > .ui-table-wrapper > table > .ui-table-thead > tr > th:last-child {
  border-top-right-radius: 10px !important;
}

.wlp-inventory-table .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr > td {
  border-bottom: 1px solid rgba(100, 100, 100, 0.15) !important;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.wlp-inventory-table .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr {
  height: 38px;
}

.wlp-inventory-table .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr > td:first-child {
  padding-left: 22px;
}

.wlp-inventory-table .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 10px !important;
}

.wlp-inventory-table .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr:last-child > td {
  border-bottom: none !important
}

.event-table > .ui-table > .ui-table-wrapper {
  border: 2px solid rgba(100, 100, 100, 0.15);
  border-radius: 10px;
}
.event-table > .p-datatable > .p-datatable-wrapper {
  border: 2px solid rgba(100, 100, 100, 0.15);
  border-radius: 10px;
}
.event-table > .p-datatable > .p-datatable-wrapper {
  border: 2px solid rgba(100, 100, 100, 0.15);
  border-radius: 10px;
  
    table {
      table-layout: fixed;
      font-size: 0.9rem !important;
      
      .p-datatable-thead {
        tr {
          th {
            background-color: #F0F0F0;
            padding: 0.25em 0.5em;
          }
        }
      }
      
      .p-datatable-tbody {        
        tr {
          td {
            border-bottom: 1px solid rgba(100,100,100,.15)!important;
            padding: 0.25em 0.5em;
          }
        }
      }
    }
}

.event-table .ui-table > .ui-table-wrapper > table > .ui-table-thead > tr > th {
  background-color: #F0F0F0;
}

.event-table .ui-table > .ui-table-wrapper > table > .ui-table-thead > tr {
  height: 32px;
}

.event-table .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr > td {
  border-bottom: 2px solid #F0F0F0;
}

.event-table .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 10px;
}

.event-table .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 10px;
}

.event-table .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr > td {
  border-bottom: 1px solid rgba(100, 100, 100, 0.15) !important;
}

.event-table .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr:last-child > td {
  border: none !important;
}

.event-table .ui-table > .ui-table-wrapper > table > .ui-table-thead > tr > th:first-child {
  border-top-left-radius: 10px;
}

.event-table .ui-table > .ui-table-wrapper > table > .ui-table-thead > tr > th:last-child {
  border-top-right-radius: 10px;
}

.event-table .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr > .userslist-multi-value-top > span > span > a > .userslist-multi-value-top {
  color: #555;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.event-table .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr > .userslist-multi-value-top > span > span > a > .userslist-multi-value-top > .userslist-multi-value-bottom {
  color: #3F57E8;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.event-table .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr > td > span > span {
  color: #323B3E;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.event-table .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr > td > span > .no-text-decoration {
  color: #000 !important;
  text-decoration: none !important;
}

.event-table .ui-table > .ui-table-wrapper > table > .ui-table-tbody > tr > .search-user-results-role > span > div {
  color: #323B3E;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.packageExpansionPanel > .mat-expansion-panel-content > .mat-expansion-panel-body{
  padding: 0px 0px 16px 0px;
}

.p-drawer-active {
  z-index: 800100;
}

.p-component-overlay-leave {
  z-index: 1000 !important;
}

.accordion-tab-header-text {
  color: black;
  font-size: 20px;
  font-family: Arial;
  font-weight: 700;
  line-height: 26px;
  word-wrap: break-word;
}

.welcome-wizard-accordion.p-accordion {
  .p-accordion-tab {
    border: 1px rgba(100, 100, 100, 0.15) solid;
    border-radius: 10px;
    margin-top: 20px;
  }

  .p-accordion-header {
    .p-accordion-header-link {
      border: none;
      background: white;
      border-radius: 10px;
      padding: 1.375rem 1.5rem;

      .p-accordion-toggle-icon-end {
        font-size: 24px;
        font-weight: 700;
        color: black;
      }
    }

    &:not(.p-disabled) {
      .p-accordion-header-link {
        &:focus {
          box-shadow: none;
        }
      }
    }

    &:not(.p-disabled).p-highlight {
      .p-accordion-header-link {
        border-bottom: none;
        background: white;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }

    &:not(.p-highlight) {
      &:not(.p-disabled) {
        &:hover {
          .p-accordion-header-link {
            background: white;
          }
        }
      }
    }
  }

  .p-accordion-content {
    background: white;
    border-radius: 0px 0px 10px 10px;
    border: none;
    padding: 1.375rem 1.5rem;
    padding-top: 0;
  }
}

p-select .p-select-label {
  margin: 0 !important;
  &:focus-visible {
    outline-color: rgb(63, 87, 232);
  }
}

.p-datepicker-group-container {
  background-color: white;
}

p-datepicker .p-inputtext {
  border: 1px solid rgba(100, 100, 100, 0.15);
  border-radius: 8px;
}

.p-popover {
  z-index: 800101 !important;
  transform: none !important;
  top: 0px;
  left: 0px;
}

p-overlayPanel {
  position: relative;
  top: -10px;
  left: 15px;
}

.p-popover::before {
  display: none;
}

.p-popover::after {
  display: none;
}

.p-popover-content {
  padding: 0px !important;
}

.p-drawer-header {
  padding: 0px !important;
  flex-direction: row-reverse;
}

.p-drawer-content {
  padding: 0px !important;
}

.p-datatable-tbody > tr > td {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.welcome-wizard-accordion .p-accordionpanel {
  margin: 15px 0px;
}

.p-select-option {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.stateSelect p-overlay .p-overlay {
  width: 211px;
}
